import { axiosApi } from "@/config/axios"

export const state = {
   youtube : {},
   youtubeLoading : false
}
export const getters = {
   get_youtube : (state)=>state.youtube,
   get_youtube_loading : (state)=>state.youtubeLoading,

}
export const mutations = {
   set_youtube :(state,data) => state.youtube = data,
   set_youtube_loading :(state,data) => state.youtubeLoading = data

}
export const actions = {
   async fetchYoutube({commit}){
       try{
          commit('set_youtube_loading',true)
           var response = await axiosApi.get("youtube")
           console.warn(response.data)
       if(response.status==200){
           commit('set_youtube',response.data)
           localStorage.setItem("channelId",response.data.channel_id)
           localStorage.setItem("apiKey",response.data.api_key)
       }
       } catch(e){
           console.warn(e)
       }finally{
        commit('set_youtube_loading',false)
       }
   }
}
