import axios from "axios"

export const state = {
   playlistitems : {},
   playlistitemsLoading : false
}
export const getters = {
   get_playlistitems : (state)=>state.playlistitems,
   get_playlistitems_loading : (state)=>state.playlistitemsLoading,

}
export const mutations = {
   set_playlistitems :(state,data) => state.playlistitems = data,
   set_playlistitems_loading :(state,data) => state.playlistitemsLoading = data

}
export const actions = {
   async fetchPlayListItems({commit},id){
       try{
         var apiKey = localStorage.getItem("apiKey");
      
          commit('set_playlistitems_loading',true)
         //   var response = await axios.get(`https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=25&playlistId=${id}&key=${apiKey}`)
           var response = await axios.get(`https://youtube.googleapis.com/youtube/v3/playlistItems`,{params:{part:'snippet,contentDetails',maxResults:10,playlistId:id,key:apiKey}})
           console.warn(response.data)
       if(response.status==200){
          
           commit('set_playlistitems',response.data)
       }
       } catch(e){
           console.warn(e)
       }finally{
        commit('set_playlistitems_loading',false)
       }
   }
}
