import Vue from 'vue'
import Vuex from 'vuex'
import * as carousel from './module/home/carousel'
import * as menu from './module/menu'
import * as messages from './module/home/message'
import * as coordinator from './module/home/coordinator'
import * as post from './module/post'
import * as school from './module/school'
import * as academic from './module/home/academic'
import * as administrative from './module/home/administrative'
import * as faq from './module/faq'
import * as gallery from './module/gallery'
import * as downloads from './module/downloads'
import * as notice from './module/notice'
import * as pedagogy from './module/pedagogy'
import * as alumini from './module/alumini'
import * as batch from './module/batch'
import * as program from './module/home/programs'
import * as latestNotice from './module/latest_notice'
import * as facilities from './module/home/facilities'
import * as playlist from './module/playlist'
import * as playlistitems from './module/playlist_items'
import * as youtube from './module/youtube'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    carousel,
    menu,
    messages,
    coordinator,
    post,
    school,
    academic,
    administrative,
    faq,
    gallery,
    downloads,
    notice,
    pedagogy,
    alumini,
    batch,
    program,
    latestNotice,
    facilities,
    playlist,
    playlistitems,
    youtube
  }
})
